import React, { PureComponent } from 'react'
import { graphql } from 'gatsby'

import * as Modules from '../components/modules'
import * as Organisms from '../components/organisms'
import { css } from '@emotion/react'
import * as Libs from 'libs'
export class Category extends PureComponent {
  //--------------------------------------
  //  Css
  //--------------------------------------

  contCss = () => css``

  constructor (props) {
    super(props)
    this.itemNum = 0
    this.counter = 0
  }
  componentDidMount () {
    Libs.Common.Config.excuteTransition()
  }
  render () {
    const { data, pageContext } = this.props
    const currentSlug = pageContext.slug
    const category = data.contentfulCommonCategory

    const items = category ? category['content___post'] : null

    this.itemNum = items ? items.length : 0

    this.itemNum = items ? items.length : 0

    const getCategoryList = slug => {
      if (!items) return ''
      if (slug === 'works') {
        return (
          <Organisms.CategoryPage
            type={'category'}
            items={items}
            slug={currentSlug}
          />
        )
      } else if (slug === 'journal') {
        return (
          <Organisms.CategoryJournalPage
            type={'category'}
            items={items}
            slug={currentSlug}
          />
        )
      } else {
        const variants = data.allShopifyProductVariant.edges

        return (
          <Modules.ProductsCategory
            lang={category.node_locale}
            type={'category'}
            items={items}
            variants={variants}
            slug={currentSlug}
          />
        )
      }
    }

    return (
      <>
        <Libs.Common.SEO.SEO
          title={category.name}
          lang={category.node_locale}
          link={category.slug}
          description={category.description.description}
        />

        <section
          css={this.contCss}
          className={'project-container init-animat-cont category'}
          data-current={category.slug}
          data-currentname={category.name}
          data-type={'category'}
          data-categoryslug={currentSlug}
          data-categoryname={category.name}
        >
          {getCategoryList(currentSlug)}
        </section>
      </>
    )
  }
}

export default Category

export const pageQuery = graphql`
  query categoryQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    contentfulCommonCategory(id: { eq: $id }) {
      id
      node_locale
      name
      slug
      description {
        description
      }
      content___post {
        slug
        name
        date
        title
        node_locale
        category {
          slug
        }
        tags {
          name
          slug
          group
        }
        thumbnail {
          description
          gatsbyImageData(width: 600, quality: 70)
          file {
            url
          }
        }
        thumbnailSp {
          description
          gatsbyImageData(width: 600, quality: 70)
          file {
            url
          }
        }
      }
    }
  }
`
